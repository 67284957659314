<template>
  <div class="docking-config">
    <div class="list-content" v-loading="loading">
      <div class="config-item">
        <div class="config-title">{{organization_name}}-会内职务展示设置</div>
        <div style="margin-top: 32px;">
          <reminder text="通过“显示”控制是否展示在组织架构下"></reminder>
        </div>
        <div class="config-content">
          <el-form class="small-form" size="small" label-width="170px" label-position="left">
            <el-form-item label="合并子架构重名职务">
              <el-radio-group v-model="config.show_children">
                <el-radio :label="1">开启</el-radio>
                <el-radio :label="0">关闭</el-radio>
              </el-radio-group>
              <div class="form-tip">
                <span class="el-icon-info" style="font-size: 15px; color:#C0C4CC"></span>
                <span>将子架构重名职务的会员数据合并展示</span>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <el-table :data="list" class="thead-light" stripe
                  style="max-width: 600px" id="sortTable" row-key="id">
          <!-- 排序 -->
          <el-table-column width="100" :index="indexMethod" label="拖拽排序">
            <span class="el-icon-sort my-handle cursor-pointer"></span>
          </el-table-column>
          <!-- 序号 -->
          <el-table-column width="100" type="index" :index="indexMethod" label="序号"></el-table-column>
          <template v-for="(th, index) in thead">
            <el-table-column :prop="th.prop" :label="th.label"
                             :width="th.width || ''" :min-width="th.minWidth || ''"
                             :key="index" show-overflow-tooltip>
              <template slot-scope="scope">
                <!-- 显示 -->
                <el-checkbox v-if="th.prop === 'is_show'" v-model="scope.row[th.prop]"
                             :true-label="1" :false-label="0">
                </el-checkbox>
                <!-- 普通 -->
                <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
    </div>
    <fixed-action-bar>
      <el-button @click="$router.back(-1)">返回</el-button>
      <el-button type="primary" :loading="loading" @click="saveConfig">保存</el-button>
    </fixed-action-bar>
  </div>
</template>

<script>
  import FixedActionBar from "../../../base/layout/FixedActionBar";
  import Reminder from "@/modules/common/components/Reminder";

  import Sortable from "@/modules/page-renovation/js/Sortable.min.js";
  import {getPositionConfig, setPositionConfig} from "@/modules/organization-show-admin/api/base-config-edit";

  export default {
    components: {
      Reminder,
      FixedActionBar
    },
    data() {
      return {
        loading: true,
        config: {
          show_children: 0
        },
        list: [],
        thead: [
          { label: "会内职务", prop: "name" },
          { label: "显示", prop: "is_show", width: 100},
        ],
        organization_name: this.$route.query.org
      }
    },
    created() {
      this.getConfig();
    },
    methods: {
      // 拖拽排序
      drag() {
        const el = document.querySelectorAll(
          "#sortTable .el-table__body > tbody"
        )[0];
        Sortable.create(el, {
          disabled: false,
          ghostClass: "sortable-ghost",
          handle: ".my-handle",
          setData: function (dataTransfer) {},
          onEnd: (e) => {
            let arr = this.list; // 获取表数据
            arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]); // 数据处理
            this.$nextTick(() => {
              this.list = arr;
            });
          },
        });
      },
      // 序号计算
      indexMethod(index) {
        return index + 1;
      },
      saveConfig() {
        this.loading = true;
        setPositionConfig({
          organization_id: this.$route.params.id,
          config: this.config,
          show_list: this.list,
        }).then(res => {
          this.$message.success(res.msg)
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      },
      getConfig() {
        this.loading = true;
        getPositionConfig(this.$route.params.id).then(res => {
          this.list = res.data.positions || [];
          this.config = res.data.config;
          this.$nextTick(() => {
            this.drag()
          })
          this.loading = false;
        }).catch(err => {
          this.loading = false;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .docking-config {
    .list-content {
      min-height: 200px;
    }

    .list-item {
      min-height: 100px;
    }

    .config-item + .config-item {
      margin-top: 79px;
    }

    .config-item {
      padding-top: 20px;
      .config-title {
        padding-left: 8px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.85);
        line-height: 16px;
        border-left: 3px #3479ef solid;
      }
      .config-content {
        margin-top: 32px;
        padding-left: 11px;
        .switch-config {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          .form-title {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 14px;
            margin-right: 15px;
          }
        }
        .form-tip {
          display: flex;
          align-items: center;
          font-size: 12px;
          color: #999;
          line-height: 12px;
          span + span {
            margin-left: 4px;
          }
        }
        .suffix {
          line-height: 34px;
          margin-left: 12px;
          color: rgba(0, 0, 0, 0.85);
        }

        .el-input-number {
          max-width: 140px;
        }
      }
    }

    .member-tag, .member-btn {
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
</style>

