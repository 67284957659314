import api from "@/base/utils/request";

export const getPositionConfig = (id) => {
  return api({
    url: '/admin/cyc/position/getShowConfig',
    method: 'post',
    data: {organization_id: id}
  })
}

export const setPositionConfig = (data) => {
  return api({
    url: '/admin/cyc/position/setShowConfig',
    method: 'post',
    data
  })
}
